module.exports = {
  email: v => (v && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(v)) || window.$i18n.t('Rule.Email'),
  length: len => v => (v && v.length >= len) || window.$i18n.t('Rule.Length').replace('%s', len),
  password: v => (v && (/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*_'""?+/()=@<>.:;{ }|~-])[a-zA-Z0-9!@#$%^&*_'""?+/()=@<>.:;{}|~-]{6,16}$/).test(v)) || window.$i18n.t('Rule.Password'),
  required: v => !!v || window.$i18n.t('Rule.Required'),
  zipCode: v => (v && (/[0-9]{4}$/).test(v)) || window.$i18n.t('Rule.ZipCode'),
  phone: v => (v && (/(^5\d{2})(\s|-)?(\d{3})(\s|-)?(\d{4})/).test(v)) || window.$i18n.t('Rule.Phone'),
  iban: v => (v && (/^TR(?:[ ]?[0-9]){24}$/).test(v)) || window.$i18n.t('Rule.Iban'),
  vknOrTckn: v => (v && (/([0-9]){10,11}$/).test(v)) || window.$i18n.t('Rule.vknOrTckn'),
  campaignCode: v => (v && (/^[A-Z]*$/).test(v)) || window.$i18n.t('Rule.CampaignCode')
};