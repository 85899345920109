<template>
  <div>
    <loading :active="$store.state.setLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-snackbar :value="$store.state.alert.show" top left :timeout="-1" :content-class="$store.state.alert.type">
      {{ $store.state.alert.message }}
    </v-snackbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'App',
  components: {
    Loading
  }
};
</script>
<style>
.success {
  background-color: green;
}

.error {
  background-color: red;
}

.warning {
  background-color: yellow;
}
.v-tab {
  text-transform: none !important;
}
.v-btn {
    text-transform: unset !important;
}
.auth-wrapper {
  min-block-size: calc(var(--vh, 0.9vh) * 100);
}
.v-data-table-header {
  background-color: #263c6f !important;
}
.v-data-table-header span {
  color: white;
}
.v-chip {
   margin: 2px !important;
   background: #3fa7dd !important;
   color: white !important;
}
.secondary-color {
  color: #263c6f !important;
}
.v-dialog {
  min-width: 50% !important;
}
.custom-checkbox {
  transform: scale(1.5);
}
.logo-area {
  border-radius: 10px !important;
}
.secondary-background {
  background-color: #263c6f !important;
}
.selectable {
  border: 1px black dotted !important;
}
</style>