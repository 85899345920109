import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    setLoading: false,
    alert: {
      show: false,
      message: '',
      type: ''
    },
    user: {},
    designComponents: [],
    selectedComponent: {},
    screen: {
        backgroundColor: '#ffffff80',
        backgroundImage: {
            model: {
                value: '',
                file: null
            }
        },
        height: 900,
        width: 1600
    },
    selectedQuickDesign: null,
    collectApiKey: 'apikey 4hwvXERefkkSBqaSxxJnX4:1Har6V0t5QS4Yzx3JRCbQ7',
    scale: 1
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations
})