<template>
    <v-app>
      <v-main>
        <v-app-bar app clipped-left flat dark color="secondary">
          <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')">
            <img src="/logo.png" width="100px" class="ml-11" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span class="user">{{ $t('Welcome') }}, {{ $store.state.user.name }} {{ $store.state.user.surname }}</span>
          </v-toolbar-title>
          <v-toolbar-items>
            <v-icon size="x-large" color="white" class="ml-3 mr-3" @click="$router.push('/profile')">mdi-account</v-icon>
            <v-icon size="x-large" color="white" @click="logout">mdi-logout</v-icon>
          </v-toolbar-items>
        </v-app-bar>
        <menu-sidebar></menu-sidebar>
        <v-breadcrumbs v-if="$route.path !== '/dashboard'" class="breadcrumb-component" :items="BreadcrumbItems"></v-breadcrumbs>
        <router-view class="dashboard-router"></router-view>
        <Footer></Footer>
      </v-main>
  </v-app>
</template>
<script>
import MenuSidebar from '@/components/MenuSidebar.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'DashboardLayout',
  components: {
    MenuSidebar,
    Footer
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    BreadcrumbItems() {
      return [
        {
          text: this.$t('Menu.Dashboard'),
          disabled: false,
          href: 'dashboard',
        },
        {
          text: this.$t(this.$route.meta?.resourceKey),
          disabled: true
        },
      ]
    }
  },
  methods: {
    logout() {
        localStorage.removeItem('token');
        this.$store.commit('set', ['user', {}]);
        this.$router.push('/login');
    }
  }
};
</script>
<style scoped>
.user {
    font-size: 15px;
    font-weight: 400;
}
.dashboard-router {
  padding: 10px;
}
.breadcrumb-component {
  background-color: #f5f5f5;
}
.v-main__wrap {
  background-color: rgb(235, 233, 233);
}
</style>
