import DashboardLayout from '@/layouts/DashboardLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import DesignLayout from '@/layouts/DesignLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          resourceKey: 'Menu.Dashboard'
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue'),
        meta: {
          resourceKey: 'Menu.Profile'
        }
      },
      {
        path: '/localizations',
        name: 'localizations',
        component: () => import('../views/admin/Localizations.vue'),
        meta: {
          resourceKey: 'Menu.Localizations',
          type: 'admin'
        }
      },
      {
        path: '/packages',
        name: 'packages',
        component: () => import('../views/admin/Packages.vue'),
        meta: {
          resourceKey: 'Menu.Packages',
          type: 'admin'
        }
      },
      {
        path: '/payment-configuration',
        name: 'payment-configuration',
        component: () => import('../views/admin/PaymentConfiguration.vue'),
        meta: {
          resourceKey: 'Menu.PaymentConfiguration',
          type: 'admin'
        }
      },
      {
        path: '/invoice-configuration',
        name: 'invoice-configuration',
        component: () => import('../views/admin/InvoiceConfiguration.vue'),
        meta: {
          resourceKey: 'Menu.InvoiceConfiguration',
          type: 'admin'
        }
      },
      {
        path: '/sales-persons',
        name: 'sales-persons',
        component: () => import('../views/admin/SalesPersons.vue'),
        meta: {
          resourceKey: 'Menu.SalesPersons',
          type: 'admin'
        }
      },
      {
        path: '/designs',
        name: 'designs',
        component: () => import('../views/admin/Designs.vue'),
        meta: {
          resourceKey: 'Menu.Designs',
          type: 'admin'
        }
      },
      {
        path: '/my-customers',
        name: 'my-customers',
        component: () => import('../views/sales_person/MyCustomers.vue'),
        meta: {
          resourceKey: 'Menu.MyCustomers',
          type: 'sales_person'
        }
      },
      {
        path: '/payment-history',
        name: 'payment-history',
        component: () => import('../views/admin/PaymentHistory.vue'),
        meta: {
          resourceKey: 'Menu.PaymentHistory',
          type: 'admin'
        }
      },
      {
        path: '/invoice-history',
        name: 'invoice-history',
        component: () => import('../views/admin/InvoiceHistory.vue'),
        meta: {
          resourceKey: 'Menu.InvoiceHistory',
          type: 'admin'
        }
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import('../views/admin/Customers.vue'),
        meta: {
          resourceKey: 'Menu.Customers',
          type: 'admin'
        }
      },
      {
        path: '/sales-person-payment-history',
        name: 'sales-person-payment-history',
        component: () => import('../views/admin/SalesPersonPaymentHistory.vue'),
        meta: {
          resourceKey: 'Menu.PaymentHistory',
          type: 'sales_person'
        }
      },
      {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('../views/admin/Campaigns.vue'),
        meta: {
          resourceKey: 'Menu.Campaigns',
          type: 'admin'
        }
      },
    ]
  },
  {
    path: '/design/:id',
    component: DesignLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/design/:id',
        name: 'design',
        component: () => import('../views/admin/Design.vue'),
        meta: {
          type: 'admin'
        }
      }
    ]
  },
  {
    path: '/preview/:id',
    component: () => import('../views//admin/Preview.vue'),
    meta: {
      requiresAuth: true,
      type: 'admin'
    }
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
      },
      {
        path: '/new-sales-person',
        name: 'new-sales-person',
        component: () => import('../views/NewSalesPerson.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue')
      },
      {
        path: '/set-password',
        name: 'set-password',
        component: () => import('../views/SetPassword.vue')
      },
      {
        path: '/sales-contract',
        name: 'sales-contract',
        component: () => import('../views/SalesContract.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
