import Vue from 'vue';
import App from './App.vue';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import store from './store';
import router from './routes/router';
import { commonHelper } from './common-helper';
import axios from 'axios';
import { ApiManager } from './api-manager';
import VueI18n from 'vue-i18n';
import tr from './locales/tr.json';
const messages = { tr };
import DeleteDialog from './components/DeleteDialog';
import AddOrUpdateDialog from './components/AddOrUpdateDialog';
import Address from './components/Address';
import VueDragDropAlign from '@xpf0000/vuedragdropalign';
import '@xpf0000/vuedragdropalign/dist/VueDragDropAlign.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueQRCodeComponent from 'vue-qrcode-component';
import FileLibrary from './components/FileLibrary.vue';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAoT9gP2MOB_9r-4y8j6olw31SOFMx_Dxs',
    libraries: 'places',
    language: 'tr-TR',
  },
  installComponents: true
});

Vue.component('vue-draggable-resizable', VueDragDropAlign);
Vue.component('qr-code', VueQRCodeComponent);
Vue.component('delete-dialog', DeleteDialog);
Vue.component('add-or-update-dialog', AddOrUpdateDialog);
Vue.component('address-view', Address);
Vue.component('file-library', FileLibrary);

//axios
const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV == 'production' ? 'https://api.holascreen.com/' : 'http://localhost:3001/',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

//globally
window.$axios = axiosInstance;
window.$commonHelper = commonHelper;
Vue.prototype.$api = new ApiManager();
Vue.prototype.$commonHelper = commonHelper;

axiosInstance.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers.common,
    "Authorization": window.$commonHelper.getCurrentToken()
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

Vue.use(VueMaterial);
Vue.use(VueI18n);
Vue.config.productionTip = false;

//localization
const i18n = new VueI18n({
  locale: 'tr',
  messages: messages,
  silentTranslationWarn: true
});

window.$i18n = i18n;

commonHelper.configureUser().then(() => {
  new Vue({
    vuetify,
    store,
    render: h => h(App),
    router,
    i18n
  }).$mount('#app')
});
