<template>
  <v-dialog v-if="isOpen" v-model="isOpen" persistent width="unset" show-close-button responsive>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-form @submit.prevent="save()" ref="addOrUpdateForm">
        <v-card-text>
            <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red white--text" variant="" @click="$emit('close')">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="green white--text" variant="text" type="submit">
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
  
<script>
  export default {
    name: 'AddOrUpdateDialog',
    props: {
      value: Boolean,
      title: String,
      model: {
        prop: "value"
      }
    },
    computed: {
      isOpen() {
        return this.value;
      }
    },
    methods: {
      save() {
        if (this.$refs.addOrUpdateForm.validate()) {
          this.$emit('save')
        }
      }
    }
  }
</script>
  