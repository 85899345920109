import { commonHelper } from './common-helper'
export class ApiManager {
    constructor(){}

    async get(url) {
      return await this.call('get', url, null);
    }

    async post(url, request) {
      return await this.call('post', url, request);
    }

    async put(url, request) {
      return await this.call('put', url, request);
    }

    async delete(url, request) {
      return await this.call('delete', url, request);
    }

    async download(url, fileName) {
      commonHelper.showLoading();
      return await window.$axios({
        method: 'get',
        url: url,
        responseType: 'arraybuffer'
      }).then(function (response) {
        commonHelper.hideLoading();

        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(this.handleError);
    }

    async call(methodType, url, request){
      commonHelper.showLoading();
      return await window.$axios({
        method: methodType,
        url: url,
        data: request
      }).then(function (response) {
        commonHelper.hideLoading();
        return response.data;
      }).catch(commonHelper.handleError)
    }
}