<template>
    <v-dialog v-if="isOpen" v-model="isOpen" persistent width="80%" show-close-button dense>
      <delete-dialog v-model="deleteDialog" @onDelete="deleteFile()" @close="deleteDialog = false;" :title="$t('General.WillDeleteFileMessage')"></delete-dialog>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('General.FileLibrary') }}</v-toolbar-title>
      </v-toolbar>
      <v-card dense>
        <v-form @submit.prevent="uploadFile()" ref="uploadForm">
          <v-card-text>
            <v-file-input dense :rules="[rules.required]" v-model="file" accept="image/*, audio/*" outlined :label="$t('Parameters.File')"></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green white--text" variant="text" type="submit">
              {{ $t('General.UploadFile') }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-divider></v-divider>
        <h2 class="ml-4">{{ $t('General.CommonFileLibrary') }}</h2>
        <v-tabs v-model="commonTab">
          <v-tab>{{ $t('General.Images') }}</v-tab>
          <v-tab>{{ $t('General.Audios') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="commonTab">
          <v-tab-item>
            <v-row dense class="ml-4 mr-4 mb-4 mt-4">
              <v-col cols="12" md="1" v-for="file in commonLibrary.filter(m => m.type.includes('image'))" :key="file.id">
               <div class="text-center">
                <span class="file-name">{{ file.name }}</span>
                <a @click="selectFile(file)"><img class="library-image" :src="file.url" /></a>
                <v-btn text dense color="red white--text" class="mt-1" @click="selectedFile = file; deleteDialog = true;">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
               </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row dense class="ml-4 mr-4 mb-4 mt-4">
              <v-col cols="12" md="1" v-for="file in commonLibrary.filter(m => m.type.includes('audio'))" :key="file.id">
               <div class="text-center">
                <span class="file-name">{{ file.name }}</span>
                <a @click="selectFile(file)"><img class="library-image" src="/files/audio.png" /></a>
                <v-btn text dense color="red white--text" class="mt-1" @click="selectedFile = file; deleteDialog = true;">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
               </div>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </template>
    
  <script>
    import validationRules from '../rules';
    export default {
      name: 'FileLibrary',
      props: {
        value: Boolean,
        type: String,
        model: {
          prop: "value"
        }
      },
      data() {
        return {
          file: null,
          myLibrary: [],
          commonLibrary: [],
          deleteDialog: false,
          selectedFile: null,
          myTab: null,
          commonTab: null,
          rules: validationRules
        };
      },
      computed: {
        isOpen() {
          return this.value;
        }
      },
      methods: {
        async uploadFile() {
          if (this.$refs.uploadForm.validate()) {
            const formData = new FormData();
            formData.append('file', this.file);
            await this.$api.post('admin/upload-files', formData);
            this.$commonHelper.showAlert('success', this.$t('General.SuccessFileUpload'));
            this.file = null;
            this.$refs.uploadForm.reset();
            this.getCommonLibrary();
          }
        },
        async getCommonLibrary() {
          this.commonLibrary = await this.$api.get('common/upload-files');
        },
        async deleteFile() {
          await this.$api.delete('admin/upload-files', {
              id: this.selectedFile.id
            });
            this.$commonHelper.showAlert('success', this.$t('General.SuccessFileDeleted'));
            this.selectedFile = null;
            this.deleteDialog = false;
            this.getCommonLibrary();
        },
        selectFile(file) {
          if (file && file.type.includes(this.type)) {
            this.$emit('selectedFile', file);
          }
        }
      },
      async mounted() {
        await this.getCommonLibrary();
      },
      watch : {
          type: function(val) {
            if (val === 'image') {
              this.myTab = 0;
              this.commonTab = 0;
            }

            if (val === 'audio') {
              this.myTab = 1;
              this.commonTab = 1;
            }
          }
      }
    }
  </script>
  <style>
  .library-image {
    object-fit: contain;
    height: 100px !important;
    width: 100%;
  }
  .file-name {
    font-size: 10px;
  }
  </style>