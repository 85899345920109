<template>
    <v-app>
      <v-main class="all-area">
        <v-app-bar app clipped-left flat dark color="secondary">
          <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')">
            <img src="/logo.png" width="100px" class="ml-11" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title style="cursor: pointer;">
            <b>Hazır Tasarım Adı: </b>{{ $store.state.selectedQuickDesign?.name}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-label dark>{{ $t('General.Scale') }}</v-label>
          <v-slider class="mt-6 mr-1" dark v-model="sliderValue" step="10" ticks @input="changeScale"></v-slider>
          <v-toolbar-items>
            <v-icon dark color="white" size="x-large" @click="save" class="ml-3 mr-3">mdi-content-save</v-icon>
            <router-link class="preview-btn" :to="`/preview/${$store.state.selectedQuickDesign?.id}`" target="_blank">{{ $t('Preview') }}</router-link>
          </v-toolbar-items>
        </v-app-bar>
        <router-view></router-view>
      </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'DashboardLayout',
  components: {},
  data() {
    return {
      sliderValue: 100
    }
  },
  methods: {
    async save() {
      await this.$api.put('admin/quick-design', {
        id: this.$store.state.selectedQuickDesign.id,
        design: {
          components: this.$store.state.designComponents,
          screen: this.$store.state.screen
        }
      });
      this.$commonHelper.showAlert('success', this.$t('UpdateSuccess'));
    },
    changeScale(val) {
      const value = parseFloat(val / 100);
      this.$store.commit('set', ['scale', value]);
    }
  }
};
</script>
<style>
.all-area {
  position: absolute;
}
.preview-btn {
  margin-top: 1.2rem;
  font-size: 17px;
  color: white !important;
}
</style>
