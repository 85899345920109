<template>
  <v-dialog v-if="isOpen" v-model="isOpen" persistent width="unset">
    <v-card>
      <v-card-title class="text-h5">{{ $t('AreYouSure') }}</v-card-title>
      <v-card-text>{{ $t(message) }}</v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red white--text" @click="$emit('close')">
              {{ $t('Cancel') }}
          </v-btn>
          <v-btn color="green white--text" @click="$emit('onDelete')">
              {{ $t('Approve') }}
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
  export default {
    name: 'DeleteDialog',
    props: {
      value: Boolean,
      model: {
        prop: "value"
      },
      message: {
        type: String,
        default: 'WillDeleteMessage'
      }
    },
    computed: {
      isOpen() {
        return this.value;
      }
    }
  }
</script>
  