<template>
    <v-app>
      <v-main>
        <router-view></router-view>
        <Footer></Footer>
      </v-main>
    </v-app>
</template>
<script>
import Footer from '@/components/Footer.vue';

 export default {
      name: 'AuthLayout',
      components: {
        Footer
      }
 }
</script>
<style scoped>
.theme--light.v-application {
  background: #263C6F !important;
}
</style>
<style>
.auth-title {
   font-size: 20px;
}
.auth-text {
   font-size: 16px;
   padding-bottom: 1rem;;
}
.login-logo {
   padding-bottom: 1rem;
   display: block;
   margin-left: auto;
   margin-right: auto;
}
.login-card {
   padding: 2rem;
}
.login-router-link {
  font-size: 15px;
  font-weight: bold;;
}
</style>
