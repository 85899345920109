<template>
  <v-row>
    <v-col :md="md" cols="12">
      <v-select :class="inputClass" :rules="[rules.required]" v-model="modelLocal.city" :items="cities" item-text="city" item-value="city" outlined dense :label="$t('City')" @input="cityChanged" />
    </v-col>
    <v-col :md="md" cols="12">
      <v-select :class="inputClass" :rules="[rules.required]" v-model="modelLocal.district" :items="districts" outlined dense :label="$t('District')" />
    </v-col>
    <v-col :md="md" cols="12">
      <v-text-field :class="inputClass" :rules="[rules.required, rules.length(5)]" v-model="modelLocal.address" outlined dense :label="$t('Address')"></v-text-field>
    </v-col>
    <v-col :md="md" cols="12">
      <v-text-field :class="inputClass" :rules="[rules.zipCode]" v-model="modelLocal.zipCode" outlined dense :label="$t('ZipCode')" :maxlength="5"></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import validationRules from '../rules';
export default {
  name: 'AddressComponent',
  props: {
    md: {
      type: String,
      default: '12'
    },
    value: {
      type: Object,
      default: () => {}
    },
    inputClass: {
      type: String,
      default: ''
    },
    model: {
      prop: 'value',
      event: 'valueChange'
    }
  },
  computed: {
    modelLocal: {
      get: function() {
          return this.value;
      },
      set: function(value) {
          this.$emit('valueChange', value)
      }
    }
  },
  data: () => ({
      rules: validationRules,
      cities: [],
      districts: [],
      firstSet: true
  }),
  async mounted() {
      this.cities = await this.$api.get('common/statics?code=cities');
      if (this.modelLocal.city) {
          this.cityChanged(this.modelLocal.city);
      }
  },
  methods: {
      cityChanged(val) {
          if (this.firstSet) {
              this.firstSet = false;
          } else {
              this.modelLocal.district = '';
          }
          if (val) {
              const city = this.cities.find(c => c.city == val);
              this.districts = city ? city.districts : [];
          } else {
              this.districts = [];
          }
      }
  }
}
</script>
  