<template>
  <v-footer class="admin-footer" relative app>
    <v-container>
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="auto">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; {{ new Date().getFullYear() }} HolaScreen
            <a href="https://akfasoft.com/" target="_blank" class="ml-3">Akfasoft</a> {{ $t('FooterProduct') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'FooterComp',
  }
</script>

<style>
.admin-footer {
  background-color: #203055 !important;
}
.admin-footer a {
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
}
.admin-footer div {
  color: white;
}
</style>
