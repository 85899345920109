<template>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        app permanent light
      >
      <v-app-bar flat dark color="secondary">
        <v-icon class="mr-3" dark @click.stop="mini = !mini;">mdi-menu</v-icon>
        <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')" absolute>
          <img src="/logo.png" width="100px"/>
        </v-toolbar-title>
      </v-app-bar>
        <v-list dense>
          <v-list-item-group v-for="(menu, index) in menu[$store.state.user.type]" v-bind:key="index">
            <v-list-item :to="menu.link">
              <v-list-item-icon>
                <v-icon color="secondary">{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="secondary-color">{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <v-divider></v-divider>
         </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: false,
      menu: {}
    };
  },
  methods: {
  },
  mounted() {
    this.menu = {
      admin: [
        {
          title: this.$t('Menu.Localizations'),
          icon: 'mdi-web',
          link: '/localizations'
        },
        {
          title: this.$t('Menu.Packages'),
          icon: 'mdi-license',
          link: '/packages'
        },
        {
          title: this.$t('Menu.PaymentConfiguration'),
          icon: 'mdi-credit-card-outline',
          link: '/payment-configuration'
        },
        {
          title: this.$t('Menu.InvoiceConfiguration'),
          icon: 'mdi-receipt-text-check-outline',
          link: '/invoice-configuration'
        },
        {
          title: this.$t('Menu.PaymentHistory'),
          icon: 'mdi-cash-clock',
          link: '/payment-history'
        },
        {
          title: this.$t('Menu.InvoiceHistory'),
          icon: 'mdi-receipt-text-clock',
          link: '/invoice-history'
        },
        {
          title: this.$t('Menu.Customers'),
          icon: 'mdi-domain',
          link: '/customers'
        },
        {
          title: this.$t('Menu.SalesPersons'),
          icon: 'mdi-account-group',
          link: '/sales-persons'
        },
        {
          title: this.$t('Menu.Designs'),
          icon: 'mdi-image-edit-outline',
          link: '/designs'
        },
        {
          title: this.$t('Menu.Campaigns'),
          icon: 'mdi-brightness-percent',
          link: '/campaigns'
        }
      ],
      sales_person: [
        {
          title: this.$t('Menu.MyCustomers'),
          icon: 'mdi-domain',
          link: '/my-customers'
        },
        {
          title: this.$t('Menu.PaymentHistory'),
          icon: 'mdi-cash-clock',
          link: '/sales-person-payment-history'
        }
      ]
    }
  }
};
</script>