import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
});

router.beforeEach ((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    const user = window.$commonHelper.getUser();
    if (window.$commonHelper.getCurrentToken() == null) {
      next({ path: '/login' })
    } else {
      if (to.matched.some(record => record.meta.type && record.meta.type !== user?.type)) {
        next({ path: '/' })
      } else {
        next();
      }
    }
  } else {
    if (window.$commonHelper.getCurrentToken()) {
      next({ path: '/dashboard' });
    } else {
      next();
    }
  }
})
export default router;
